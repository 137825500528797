import { createSlice } from "@reduxjs/toolkit";

import { format } from "date-fns";

const initialRelatorioCfop = {
  tipoMovimento: "TODOS",
  page: 50,
  isShowFilter: false,
};

const date = new Date();
const firstDay = new Date(date.getFullYear(), date.getMonth(), 1, 1);
const currentDay = new Date(
  date.getFullYear(),
  date.getMonth(),
  date.getDate(),
  1,
);

const initialState = () => {
  return {
    ...initialRelatorioCfop,
    issue_date_start: format(firstDay, "yyyy-MM-dd"),
    issue_date_end: format(currentDay, "yyyy-MM-dd"),
  };
};

export const relatorioCfopSlice = createSlice({
  name: "relatorioCfopCsts",
  initialState,
  reducers: {
    handleChangeDate: (state, { payload }) => {
      if (payload?.issue_date_start) {
        state.issue_date_start = payload.issue_date_start;
      }
      if (payload?.issue_date_end) {
        state.issue_date_end = payload.issue_date_end;
      }
    },
    handleChangeMovement: (state, { payload }) => {
      state.tipoMovimento = payload.tipoMovimento;
    },
    handlePageAndLimit: (state, { payload }) => {
      state.page = payload;
    },
    handleToogleFilters: (state, { payload }) => {
      state.isShowFilter = payload.isShowFilter;
    },
  },
});

export const {
  handleChangeDate,
  handleChangeMovement,
  handlePageAndLimit,
  handleToogleFilters,
} = relatorioCfopSlice.actions;

export default relatorioCfopSlice.reducer;
