import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";

import { handleParamsStartEnd } from "redux/taxAnalises";

import { DateSimples } from "components/contexts/TaxAnalysis/List/Header/Filter/DateSimple";

import { Row, Col, Button } from "reactstrap";

export const FilterAdvanced = () => {
  const { issue_date_end: end, issue_date_start: start } = useSelector(
    state => state.taxAnalises,
  );
  const { control, handleSubmit, setValue } = useForm({});

  const dispatch = useDispatch();

  const onSave = fields => {
    if (fields?.start && fields?.end) {
      dispatch(
        handleParamsStartEnd({
          issue_date_end: fields?.end
            ? format(fields?.end, "yyyy-MM-dd")
            : format(fields?.start, "yyyy-MM-dd"),
          issue_date_start: format(fields?.start, "yyyy-MM-dd"),
        }),
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSave)}>
      <h3>Filtro avançado</h3>
      <Row className="mt-1">
        <Col md="6">
          <DateSimples
            name="start"
            setValue={setValue}
            control={control}
            value={start}
          />
        </Col>
        <Col md="6">
          <DateSimples
            name="end"
            setValue={setValue}
            control={control}
            value={end}
            title="Data Final"
          />
        </Col>
      </Row>
      <Col md="12" className="text-center">
        <Button className="mt-1" color="success" type="onSumit">
          Filtrar
        </Button>
      </Col>
    </form>
  );
};
