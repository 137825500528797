import { useRef, useState } from "react";
import axios from "axios";
import {
  getSessionToken,
  getSessionTemplateToken,
  getSessionCompanies,
  getSessionTokenRobot,
} from "utils/session";
import { useToast } from "./useToast";

const AUTHENTICATION_BASE_URL =
  process.env.REACT_APP_AUTHENTICATE ??
  "https://authentication-builder.mixfiscal.com.br/v1";

const TEMPLATE_BASE_URL =
  process.env.REACT_APP_TEMPLATE ||
  "https://templates-builder.mixfiscal.com.br/api";

const NFE_BASE_URL =
  process.env.REACT_APP_NFE_API_XML ?? "https://api-xml.mixfiscal.com.br/api";

const API_ROBOT = "https://robotapi.mixfiscal.com.br/";

const WHATSAPP_URL =
  process.env.REACT_APP_API_WHATSAPP_INTEGRATION ||
  "https://api.builder.mixfiscal.com.br/app-integration";

const MIX_PRICING_URL =
  process.env.REACT_APP_MIX_PRICING_URL ||
  "https://api.builder.mixfiscal.com.br/app-integration";

const AUTHENTICATION_JOBS_URL =
  "https://dev.authentication.mixfiscal.com.br/jobs";

export const useAPIAuthenticate = () => {
  // TODO: Adicionar toast automatico quando as requisições falharem!
  const onRejected = error => {
    // if (error.toJSON().message === "Network Error") {
    //   addToast({
    //     type: "alert",
    //     description:
    //       "Não foi possível se conectar a internet. Verifique sua conexão",
    //   });
    // }

    return Promise.reject(error);
  };

  const apiRef = useRef(
    axios.create({
      baseURL: AUTHENTICATION_BASE_URL,
    }),
  );

  apiRef.current.interceptors.request.use(config => {
    config.headers.Authorization = getSessionToken();
    if (getSessionCompanies() !== undefined) {
      config.headers["X-token-user"] = getSessionCompanies();
    }
    return config;
  });

  apiRef.current.interceptors.response.use(response => response, onRejected);

  return apiRef.current;
};

export const useAPIAuthenticateToast = apiRef => {
  const [isLoading, setIsLoading] = useState(false);
  const api = useAPIAuthenticate();
  const { addToast } = useToast();

  const onSend = async ({
    messageSuccess,
    messageFail,
    endpoint,
    type = "POST",
    data: dataFields = {},
    disabledMsg = false,
  }) => {
    try {
      setIsLoading(true);
      const { data } = await (apiRef ?? api)({
        method: type,
        url: endpoint,
        data: dataFields,
      });

      if (data.response ?? data.success) {
        if (!disabledMsg) {
          addToast({
            type: "success",
            title: "SUCESSO",
            description: messageSuccess,
          });
        }
      }
      setIsLoading(false);
      return data;
    } catch (err) {
      if (!disabledMsg) {
        addToast({
          type: "error",
          title: "FALHA",
          description: messageFail,
        });
      }
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    onSend,
  };
};
export const useAPINFE2 = () => {
  const onRejected = error => {
    // console.log(error.response);
    return Promise.reject(error);
  };

  const apiRef = useRef(
    axios.create({
      baseURL: NFE_BASE_URL,
    }),
  );

  apiRef.current.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${getSessionCompanies()}`;
    return config;
  });

  apiRef.current.interceptors.response.use(response => response, onRejected);

  return apiRef.current;
};

export const useAPIRobot = () => {
  const onRejected = error => {
    // console.log(error.response);
    return Promise.reject(error);
  };

  const apiRef = useRef(
    axios.create({
      baseURL: API_ROBOT,
    }),
  );

  apiRef.current.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${getSessionTokenRobot()}`;
    return config;
  });

  apiRef.current.interceptors.response.use(response => response, onRejected);

  return apiRef.current;
};

export const useAPIAuthJob = () => {
  const onRejected = error => {
    return Promise.reject(error);
  };

  const apiRef = useRef(
    axios.create({
      baseURL: AUTHENTICATION_JOBS_URL,
    }),
  );

  apiRef.current.interceptors.response.use(response => response, onRejected);

  return apiRef.current;
};

export const useAPINFE2Toast = apiRef => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const api = useAPINFE2();
  const { addToast } = useToast();

  const onSend = async ({
    messageSuccess,
    messageFail,
    endpoint,
    type = "POST",
    data: dataFields = {},
  }) => {
    try {
      setIsLoading(true);
      const { data } = await (apiRef ?? api)({
        method: type,
        url: endpoint,
        data: dataFields,
      });

      if (data.response ?? data.success) {
        console.log({ data });
        addToast({
          type: "success",
          title: "SUCESSO",
          description: data?.msg || messageSuccess,
        });
        setData(data);
      }
      setData(data);
      setIsLoading(false);
      return data;
    } catch (err) {
      setData(err);
      addToast({
        type: "error",
        title: "FALHA",
        description: messageFail,
      });
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    data,
    onSend,
  };
};

export const useAPI = () => {
  const { addToast } = useToast();

  const onRejected = error => {
    addToast({
      type: "error",
      title: "Falha",
      description: `Falha na requisição`,
    });

    return Promise.reject(error);
  };

  const apiRef = useRef(
    axios.create({
      baseURL: TEMPLATE_BASE_URL,
    }),
  );

  apiRef.current.interceptors.request.use(config => {
    config.headers.Authorization = getSessionTemplateToken();
    return config;
  });

  apiRef.current.interceptors.response.use(response => response, onRejected);

  return apiRef.current;
};

export const useAPIWhatsApp = () => {
  const { addToast } = useToast();

  // TODO: Adicionar toast automatico quando as requisições falharem!
  const onRejected = error => {
    if (error.toJSON().message === "Network Error") {
      addToast({
        type: "alert",
        description:
          "Não foi possível se conectar a internet. Verifique sua conexão",
      });
    }

    return Promise.reject(error);
  };

  const apiRef = useRef(
    axios.create({
      baseURL: WHATSAPP_URL,
    }),
  );

  apiRef.current.interceptors.request.use(config => {
    config.headers.Authorization = getSessionToken();
    if (getSessionCompanies() !== undefined) {
      config.headers["X-token-user"] = getSessionCompanies();
    }
    return config;
  });

  apiRef.current.interceptors.response.use(response => response, onRejected);

  return apiRef.current;
};

export const useAPIDiagnostic = () => {
  const baseURL = process.env.REACT_APP_DIAGNOSTICO_API;
  const axiosInstance = axios.create({ baseURL });

  const axiosRef = useRef(axiosInstance);

  axiosRef.current.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${getSessionCompanies()}`;
    return config;
  });

  return axiosRef.current;
};

export const useAPIDiagnosticToast = apiRef => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const api = useAPIDiagnostic();
  const { addToast } = useToast();

  const onSend = async ({
    messageSuccess,
    messageFail,
    endpoint,
    type = "POST",
    data: dataFields = {},
  }) => {
    try {
      setIsLoading(true);
      const { data } = await (apiRef ?? api)({
        method: type,
        url: endpoint,
        data: dataFields,
      });

      if (data.response ?? data.success) {
        addToast({
          type: "success",
          title: "SUCESSO",
          description: messageSuccess,
        });
        setData(data);
      }
      setData(data);
      setIsLoading(false);
      return data;
    } catch (err) {
      setData(err);
      addToast({
        type: "error",
        title: "FALHA",
        description: messageFail,
      });
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    data,
    onSend,
  };
};

export const useAPIMixPricing = ({ version } = { version: "v1" }) => {
  const { addToast } = useToast();

  // TODO: Adicionar toast automatico quando as requisições falharem!
  const onRejected = error => {
    if (error.toJSON().message === "Network Error") {
      addToast({
        type: "alert",
        description:
          "Não foi possível se conectar a internet. Verifique sua conexão",
      });
    }

    return Promise.reject(error);
  };

  const apiRef = useRef(
    axios.create({
      baseURL: `${MIX_PRICING_URL}/${version}`,
    }),
  );

  apiRef.current.interceptors.request.use(config => {
    config.headers.Authorization = getSessionToken();
    if (getSessionCompanies() !== undefined) {
      config.headers["X-token-user"] = getSessionCompanies();
    }
    return config;
  });

  apiRef.current.interceptors.response.use(response => response, onRejected);

  return apiRef.current;
};

export const useAPIAuthenticateJob = apiRef => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const api = useAPIAuthJob();
  const { addToast } = useToast();

  const onSend = async ({
    messageSuccess,
    messageFail,
    endpoint,
    type = "POST",
    data: dataFields = {},
  }) => {
    try {
      setIsLoading(true);
      const { data } = await (apiRef ?? api)({
        method: type,
        url: endpoint,
        data: dataFields,
      });

      if (data.response ?? data.success) {
        addToast({
          type: "success",
          title: "SUCESSO",
          description: data?.msg || messageSuccess,
        });
        setData(data);
      }
      setData(data);
      setIsLoading(false);
      return data;
    } catch (err) {
      setData(err);
      addToast({
        type: "error",
        title: "FALHA",
        description: messageFail,
      });
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    data,
    onSend,
  };
};
