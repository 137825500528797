import { useCallback, useRef, useState } from "react";
import axios from "axios";

import { useToast } from "./useToast";

const CMF_BASE_URL = "https://cmf.agendador.mixfiscal.com.br/";

export const useCmfAPI = () => {
  const onRejected = error => {
    // console.log(error.response);
    return Promise.reject(error);
  };

  const apiRef = useRef(
    axios.create({
      baseURL: CMF_BASE_URL,
      withCredentials: false,
    }),
  );

  apiRef.current.interceptors.request.use(config => {
    config.headers = {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Authorization",
      "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, PATCH, DELETE",
      "Content-Type": "application/json;charset=utf-8",
      Connection: "keep-alive",
      "X-Powered-By": "X-Powered-By",
    };
    return config;
  });

  apiRef.current.interceptors.response.use(response => response, onRejected);

  return apiRef.current;
};

export const useAPICmfToast = () => {
  const [isLoading, setIsLoading] = useState(false);
  const api = useCmfAPI();
  const { addToast } = useToast();

  const onSend = async ({
    messageSuccess,
    messageFail,
    endpoint,
    type = "POST",
    data: dataFields = {},
  }) => {
    try {
      setIsLoading(true);
      const { data } = await api({
        method: type,
        url: endpoint,
        data: dataFields,
      });

      if (data.response ?? data.success) {
        addToast({
          type: "success",
          title: "SUCESSO",
          description: messageSuccess,
        });
      }
      setIsLoading(false);
      return data;
    } catch (err) {
      addToast({
        type: "error",
        title: "FALHA",
        description: messageFail,
      });
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    onSend,
  };
};

export const useFetchCmfAgendador = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [columns, setColumns] = useState([]);

  const apiCmfAgendador = useCmfAPI();
  const fetch = useCallback(
    async body => {
      setLoading(true);
      await apiCmfAgendador
        .post("/executar-sql", body)
        .then(res => {
          setLoading(false);
          setData(res?.data?.data?.result);
          setColumns(res?.data?.data?.columns);
        })
        .catch(error => {
          const success = error?.response?.data?.data?.message;
          setError(success);
          setLoading(false);
          setData([]);
          setColumns([]);
        });
    },
    [apiCmfAgendador, setColumns, setData],
  );

  return { fetch, columns, data, isLoading: loading, error };
};
