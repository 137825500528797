export const TEXT_ACTIONS = {
  kill: {
    title: "Confirmar restart",
    text: "Confirme o restart da aplicação instalado no cliente",
  },
  "clear-localdb": {
    title: "Confirmar limpeza",
    text: "Confirme a limpeza da aplicação instalada no cliente.",
  },
};
