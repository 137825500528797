import { useFetchMixAPi1, useMixApi1Toast } from "hooks";
import { getURLWithParams } from "utils/context";

export const useIcmsNf = (params = {}) =>
  useFetchMixAPi1(getURLWithParams("legislacao/nota-fiscal", params));

export const useIcmsNfDetail = id =>
  useFetchMixAPi1(id ? `legislacao/nota-fiscal/detalhe/${id}` : null);

export const useIcmsNfActionsCreate = () => {
  const { isLoading, data, onSend } = useMixApi1Toast();

  const onNfCreate = async data => {
    const res = await onSend({
      endpoint: `legislacao/nota-fiscal/novo`,
      messageSuccess: `Criado com sucesso`,
      messageFail: `Requisão falhou!`,
      type: "POST",
      data,
    });
    return res;
  };
  return {
    onNfCreate,
    isLoading,
    data,
  };
};
