import { createSlice } from "@reduxjs/toolkit";

export const inicialClassificao = {
  geoMap: {},
  params: {
    limit: 10,
    page: 1,
    descricao: "",
  },
};

const initialState = () => {
  return inicialClassificao;
};

export const classificacaoSlice = createSlice({
  name: "classificacao",
  initialState,
  reducers: {
    handleParams: (state, { payload }) => {
      const field = payload?.field;
      state.params[field] = payload?.value;
      return state;
    },
  },
});

export const { handleParams } = classificacaoSlice.actions;

export default classificacaoSlice.reducer;
