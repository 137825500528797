import { createSlice } from "@reduxjs/toolkit";

export const inicialIcmsNF = {
  params: {
    page: 1,
  },
};

const initialState = () => {
  return inicialIcmsNF;
};

export const icmsNotasFiscaisSlice = createSlice({
  name: "Icms Notas Fiscais",
  initialState,
  reducers: {
    handleParams: (state, { payload }) => {
      const field = payload?.field;
      state.params[field] = payload?.value;
      return state;
    },
  },
});

export const { handleParams } = icmsNotasFiscaisSlice.actions;

export default icmsNotasFiscaisSlice.reducer;
