import { useCallback } from "react";
import { useMixApi1 } from "hooks";
import { getURLWithParams } from "utils/context";

export const useFetchFilterClassification = () => {
  const api = useMixApi1();

  const fetchFilter = useCallback(
    async (params = {}) => {
      try {
        const response = await api.get(
          getURLWithParams("/classificacao/filtros", params),
        );

        const data = response.data || [];
        return data;
      } catch (error) {
        console.error("Error fetching stores:", error);
        return [];
      }
    },
    [api],
  );

  return {
    fetchFilter,
  };
};
