import { useFetchMixAPi1, useMixApi1 } from "hooks";
import { useCallback } from "react";
import { getURLWithParams } from "utils/context";

export const useCestlist = (params = {}) =>
  useFetchMixAPi1(getURLWithParams("/legislacao/cest", params));

export const useCestSelectAsync = () => {
  const api = useMixApi1();

  const fetch = useCallback(
    async (params = {}) => {
      try {
        const response = await api.get(
          getURLWithParams("/legislacao/cest/search", params),
        );

        const data = response.data || [];
        return data;
      } catch (error) {
        console.error("Error fetching stores:", error);
        return [];
      }
    },
    [api],
  );
  return {
    fetch,
  };
};
