import {
  useFetchAuthenticate,
  useFetchNfe,
  useAPIAuthenticateToast,
} from "hooks";

import { getURLWithParams } from "utils/context";

export const useReports = (params = {}) =>
  useFetchAuthenticate(getURLWithParams("/reports", params));

export const useReportsCfopCsts = (params = {}) =>
  useFetchNfe(getURLWithParams("/reports/cfops-csts", params));

export const useReportsSkus = (params = {}) =>
  useFetchNfe(getURLWithParams("/reports/products-abc", params));

export const useReportsDocumentosFiscais = (params = {}) =>
  useFetchNfe(getURLWithParams("/reports/documentos-fiscais", params));

export const useReportsDocumentosFiscaisXMLActions = () => {
  const { isLoading, onSend } = useAPIAuthenticateToast();

  const onCSVGenerate = data =>
    onSend({
      endpoint: "/action/gerar-csv-xmls",
      messageSuccess: "Arquivo esta sendo processado favor verificar o email!",
      messageFail: "Falha ao tentar gerar CSV!",
      data,
    });

  return {
    onCSVGenerate,
    isLoading,
  };
};
