import { useState, useCallback } from "react";
import {
  useFetchAuthenticate,
  useAPIAuthenticate,
  useAPIAuthenticateToast,
  usePostFetchAuthenticate,
} from "hooks";

import { getURLWithParams } from "utils/context";

export const useProduct = id =>
  useFetchAuthenticate(id ? `/product/${id}` : null);

export const useProductData = () => {
  const api = useAPIAuthenticate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const fetch = useCallback(
    (id = "") => {
      setLoading(true);
      const fetchData = async () => {
        try {
          const {
            data: { product },
          } = await api.get(`/product/mix/${id}`);
          setData(product);
          setLoading(false);
        } catch (e) {
          setLoading(false);
          console.log(e.messagge);
        }
      };
      fetchData();
    },
    [api],
  );

  return { isLoading: loading, data, fetch };
};

export const useProductPostOrPut = item => {
  const { isLoading, onSend } = useAPIAuthenticateToast();

  const onSave = data =>
    onSend({
      endpoint: item.id ? `/product/mix/${item.id}` : "/products/mix",
      messageSuccess: `Produto ${
        item.id ? "atualizada" : "criado"
      } com sucesso!`,
      messageFail: "Falha ao tentar salvar produto!",
      data,
      type: item.id ? "PUT" : "POST",
    });

  const onRemove = () =>
    onSend({
      endpoint: item.id ? `/product/mix/${item.id}` : "/products/mix",
      messageSuccess: `Produto removido com sucesso!`,
      messageFail: "Falha ao tentar remover produto!",
      type: "DELETE",
    });

  return {
    onSave,
    onRemove,
    isLoading,
  };
};

export const useProductRemove = () => {
  const { isLoading, onSend } = useAPIAuthenticateToast();

  const onRemove = id => {
    onSend({
      endpoint: `/product/mix/${id}`,
      messageSuccess: `Produto removido com sucesso!`,
      messageFail: "Falha ao tentar remover produto!",
      type: "DELETE",
    });
  };

  return {
    onRemove,
    isLoading,
  };
};

export const useProductsMix = (params = {}) =>
  useFetchAuthenticate(!params ? null : getURLWithParams(`/products`, params));

export const useMercadologico = (params = {}) =>
  useFetchAuthenticate(getURLWithParams(`/mercadologico`, params));

export const useProductsMixSearching = () => {
  const api = useAPIAuthenticate();
  const [dataItems, setData] = useState(null);
  const [totalDocs, setTotalDocs] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const fetch = useCallback(
    (params = {}) => {
      setLoading(true);
      const fetchData = async () => {
        try {
          const { data } = await api.post("/products/searching", {
            ...params,
          });
          setData(data?.docs);
          setTotalDocs(data?.total);
          setTotalPages(data?.totalPages);
          setLoading(false);
        } catch (e) {
          console.log(e.messagge);
        }
      };
      fetchData();
    },
    [api],
  );

  return { isLoading: loading, data: dataItems, totalPages, totalDocs, fetch };
};

export const useProductsSearching = params =>
  usePostFetchAuthenticate(params ? "/products/searching" : null, params);
