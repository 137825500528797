import { createSlice } from "@reduxjs/toolkit";

export const inicialPortaria = {
  params: {
    limit: 10,
    page: 1,
    descricao: "",
  },
};

const initialState = () => {
  return inicialPortaria;
};

export const PortariaSlice = createSlice({
  name: "portaria",
  initialState,
  reducers: {
    handleParams: (state, { payload }) => {
      const field = payload?.field;
      state.params[field] = payload?.value;
      return state;
    },
  },
});

export const { handleParams } = PortariaSlice.actions;

export default PortariaSlice.reducer;
