import { format } from "date-fns";

export const formatActivities = activity => {
  return activity.map(item => item.code).join(", ");
};

export const cestFormat = input => {
  // Remove any non-digit characters from the input
  const cleanInput = input.replace(/\D/g, "");

  // Ensure the input has exactly 7 digits
  if (cleanInput.length !== 7) {
    return "Invalid input";
  }

  // Split the input into three parts and join them with dots
  const formattedNumber = `${cleanInput.slice(0, 2)}.${cleanInput.slice(
    2,
    5,
  )}.${cleanInput.slice(5, 7)}`;

  return formattedNumber;
};

export const validatePassword = passwordValue => {
  const regex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})",
  );
  regex.lastIndex = 0;
  const isValidated = regex.test(passwordValue);
  regex.lastIndex = 0;
  return isValidated;
};
export const formatSocialName = (qsa, position) => {
  if (qsa.length < 0) return "";

  if (!qsa[position]) return "";

  qsa = qsa.sort(e => e.nome);

  const professionName = qsa[position].qual.split("-")[1];
  return `${qsa[position].nome} - ${professionName}`.toUpperCase();
};

export const onlyNumbers = value => (value || "").replace(/\D/g, "");

export const convertKey = value => {
  return value
    ?.slice(value?.length - 6, value?.length)
    .padStart(32, "*")
    .toUpperCase();
};

export const getOnlyNumbers = str => {
  str = String(str);
  const res = str.replace(/\D/g, "");
  return res;
};

export const hasMatchNumbers = (cnpj, search) =>
  onlyNumbers(cnpj).includes(search);

export const cnpjGetNumbers = value => value.replace(/\D/gi, "");

export const formatCnpj = value => {
  if (!value) return null;

  const rawValue = value.toString().replace(/\D/g, "");

  if (rawValue.length < 14) return rawValue.toString();

  return rawValue.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    "$1.$2.$3/$4-$5",
  );
};
// TODO:: PARA CHECAR SE E NÚMERO
export const ischeckIfAndNumber = value =>
  /^-?\d+$/.test(value) || /^-?\d+\.\d+$/.test(value);

export const hasLetters = value => new RegExp(/[a-zA-Z]/).test(value);

export const hasOnlySpecialChars = value =>
  new RegExp(/^[^a-zA-Z0-9]+$/).test(value);

export const hasCorrectCNPJChars = value => {
  if (value.at(2) !== undefined && value.at(2) !== ".") {
    return false;
  }
  if (value.at(6) !== undefined && value.at(6) !== ".") {
    return false;
  }
  if (value.at(10) !== undefined && value.at(10) !== "/") {
    return false;
  }
  if (value.at(15) !== undefined && value.at(15) !== "-") {
    return false;
  }
  return true;
};

export const dynamicFormatCNPJ = value => {
  if (!value) return null;

  if (hasLetters(value)) return false;

  const rawValue = value.toString().replace(/\D/g, "");

  if (rawValue.length < 2) {
    return rawValue;
  }
  if (rawValue.length >= 2 && rawValue.length < 5) {
    return rawValue.replace(/(\d{2})/, "$1.");
  }
  if (rawValue.length >= 5 && rawValue.length < 8) {
    return rawValue.replace(/(\d{2})(\d{3})/, "$1.$2.");
  }
  if (rawValue.length >= 8 && rawValue.length < 12) {
    return rawValue.replace(/(\d{2})(\d{3})(\d{3})/, "$1.$2.$3/");
  }
  if (rawValue.length >= 12 && rawValue.length < 14) {
    return rawValue.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, "$1.$2.$3/$4-");
  }
  return formatCnpj(value);
};

export const hasMatchMasked = (cnpj, search) =>
  (cnpj || "").includes(dynamicFormatCNPJ(search)) &&
  hasCorrectCNPJChars(search) &&
  !hasOnlySpecialChars(search);

export const hasMatchCNPJ = (cnpj, search) =>
  search.length &&
  (hasMatchNumbers(cnpj, search) || hasMatchMasked(cnpj, search));

export const formatCep = value => {
  if (!value) return null;

  const rawValue = value.replace(/\D/gi, "");

  if (rawValue.length === 8) return rawValue.replace(/(\d{3}$)/gi, "-$1");

  return rawValue.length < 8 ? rawValue : rawValue.slice(0, 7);
};

export const ellipsi = (value, size = 50) =>
  String(value).length > size
    ? `${String(value).substring(0, size)}...`
    : value;

export const transformListSelect = data =>
  Object.keys(data).map(item => ({
    value: item,
    label: data[item],
  }));

export const getUniqsBy = (itens, field = "_id") =>
  itens.reduce(
    (acc, cur) => [...acc.filter(obj => obj[field] !== cur[field]), cur],
    [],
  );

export const formatCurrency = (value, options = {}) =>
  Number(value).toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
    ...options,
  });
export const formatCurrencyWithoutFraction = value =>
  formatCurrency(!isNaN(value) ? parseInt(value, 10) : 0, {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

export const formatCurrencyNoReplace = value => {
  if (!value) return "-";

  const vl = parseFloat(`${value}`.replace("R$ ", ""));
  return vl?.toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
  });
};

export const listCfop = data => {
  const resp = [];
  if (data[0]) {
    data.map(item => {
      if (!resp.includes(item.prod.CFOP)) resp.push(item.prod.CFOP);
    });
  } else {
    resp.push(data.prod.CFOP);
  }
  return resp.join("; ");
};
export const regexCheckCnpjOrText = value => {
  const regexNumber = new RegExp("[0-9]");
  const regexText = new RegExp("[A-Za-z]");

  if (regexText.test(value) && regexNumber.test(value)) {
    return {
      value,
      type: "numbertext",
    };
  }
  if (regexNumber.test(value)) {
    return {
      value: formatCnpj(value),
      type: "number",
    };
  }

  if (regexText.test(value)) {
    return {
      value,
      type: "text",
    };
  }

  return "";
};

export const splitMulti = (str, tokens) => {
  const tempChar = tokens[0]; // We can use the first token as a temporary join character
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i < tokens.length; i++) {
    str = str.split(tokens[i]).join(tempChar);
  }
  str = str.split(tempChar);
  return str;
};

export const percentualDiffCorrect = (a, b, withSignal = false) => {
  const negative = b > a;
  const valueNegative = Math.abs(
    -1 * (100 * Math.abs((a - b) / ((a + b) / 2))),
  ).toFixed(2);
  const valuePositive = 100 * Math.abs((a - b) / ((a + b) / 2)).toFixed(2);

  const value = negative ? valueNegative : valuePositive;

  return withSignal
    ? { signal: negative ? "negative" : "positive", value }
    : value;
};

export const percentualDiff = (a, b) => (a && b ? (a / b) * 100 : 0).toFixed(2);

export const capitalizeFirstLetter = string =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const treatFilter = (filters = []) => {
  const itemFilter = [];
  filters
    .filter(item => item.columnField?.columnField)
    .map(item => {
      const up = {};

      if (item.columnField?.columnField === "data_emissao") {
        up[item?.columnField?.columnField] = {
          start: format(
            item?.columnField?.start
              ? item?.columnField?.start
              : item?.columnField?.value[0],
            "yyyy-MM-dd",
          ),
          end: format(
            item?.columnField?.end
              ? item?.columnField?.end
              : item?.columnField?.value[0],
            "yyyy-MM-dd",
          ),
          operatorValue: item?.columnField?.operatorValue,
          columnOrField: item?.columnField?.columnOrField,
        };
        itemFilter.push(up);
        return up;
      }
      if (item.columnField?.columnField === "cenario") {
        up[item.columnField?.columnField] = {
          uf_origin: item.columnField?.uf_origin,
          uf_destiny: item.columnField?.uf_destiny,
          scenery_exit: item.columnField?.scenery_exit,
          scenery_entry: item.columnField?.scenery_entry,
          company_type: item.columnField?.company_type,
          operatorValue: item?.columnField?.operatorValue,
          columnOrField: item?.columnField?.columnOrField,
        };
        itemFilter.push(up);
        return up;
      }
      up[item.columnField?.columnField] = {
        value: item?.columnField?.value,
        operatorValue: item?.columnField?.operatorValue,
        columnOrField: item?.columnField?.columnOrField,
      };
      itemFilter.push(up);
      return up;
    });

  return itemFilter;
};

export const limitCaracter = (limit = 35, param) => {
  const newData = param
    ? param.length <= limit
      ? param
      : `${param.substr(0, limit)}...`
    : "-";
  return newData;
};

export const formatDecimaisFixed = (value, fixed = 2) => {
  return parseFloat(value).toFixed(fixed);
};

export const formatCurrentMonay = value => {
  if (value?.includes("R$") || value?.includes("%")) {
    const val = value.replace(/[^0-9]/g, "");
    const newValue = parseInt(val);

    return newValue === 0 ? "---" : value;
  }
  return value;
};
