import { createSlice } from "@reduxjs/toolkit";

export const inicialFiscalClassification = {
  classType: "setor",
  classification: true,
  showModal: {
    show: false,
    value: {},
  },
  correct: {
    id: "",
    value: false,
  },
  selectedRows: [],
  showIcon: {
    show: false,
    value: "",
  },
};

const initialState = () => {
  return inicialFiscalClassification;
};

export const fiscalClassificationSlice = createSlice({
  name: "classificacaoFiscal",
  initialState,
  reducers: {
    handleSetClassType: (state, { payload }) => {
      state.classType = payload;
    },
    handleShowClassification: (state, { payload }) => {
      state.classification = payload;
    },
    handleShowModal: (state, { payload }) => {
      state.showModal.show = payload.show;
      state.showModal.value = payload.value;
    },
    handleSetCorrect: (state, { payload }) => {
      state.correct.value = payload.value;
      state.correct.id = payload.id;
    },
    handleSelectRow: (state, { payload }) => {
      const rowSelected = [...state.selectedRows];

      const checkExists = rowSelected?.filter(
        item => item?.prod === payload?.prod,
      );
      if (checkExists?.length) {
        state.selectedRows = rowSelected?.filter(
          item => item?.prod !== payload?.prod,
        );
      } else {
        const updateSelecteds = rowSelected.map(item => {
          return {
            ...item,
          };
        });
        state.selectedRows = [...updateSelecteds, payload];
      }
    },
    handleShowIcons: (state, { payload }) => {
      state.showIcon.show = payload?.show;
      state.showIcon.value = payload?.value;
    },
  },
});

export const {
  handleSetClassType,
  handleShowClassification,
  handleShowModal,
  handleSetCorrect,
  handleSelectRow,
  handleShowIcons,
} = fiscalClassificationSlice.actions;

export default fiscalClassificationSlice.reducer;
