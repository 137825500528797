import { useState } from "react";
import { useAPIAuthenticate, useToast } from "hooks";

// export const useStoreForm = item => {
//   const { isLoading, onSend } = useAPIAuthenticateToast();

//   const onSave = data =>
//     onSend({
//       endpoint: item.id ? `/store/${item.id}` : "store",
//       messageSuccess: `Loja ${item.id ? "atualizada" : "criada"} com sucesso!`,
//       messageFail: "Falha ao tentar salvar Loja!",
//       data,
//       type: item.id ? "PUT" : "POST",
//     });

//   return {
//     onSave,
//     isLoading,
//   };
// };

export const useStoreDadosCertificate = () => {
  const [isLoading, setIsLoading] = useState(false);
  const api = useAPIAuthenticate();
  const { addToast } = useToast();
  const onSave = async (values = {}) => {
    try {
      const nfeFormData = new FormData();
      nfeFormData.append("file", values.certificate);
      nfeFormData.append("password", values.certificatePassword);
      nfeFormData.append("cnpj", values.cnpj);
      nfeFormData.append("nickname", values.companyName);
      await api.post(`/store/certificate`, nfeFormData);

      setIsLoading(false);
    } catch (e) {
      const { data } = e.response;
      addToast({
        type: "error",
        title: "FALHA",
        align: "top",
        description: data?.message || "FALHA AO CADASTRAR CERTIFICADO A1!",
      });
      setIsLoading(false);
    }
  };

  return {
    onSave,
    isLoading,
  };
};
