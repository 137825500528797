import { createSlice } from "@reduxjs/toolkit";

export const initialTaxLogs = {
  params: {
    limit: 10,
    page: 1,
  },
  filter: {},
  show: true,
};

const initialState = () => initialTaxLogs;

export const taxLogsSlice = createSlice({
  name: "taxLogs",
  initialState,
  reducers: {
    handleParams: (state, { payload }) => {
      const field = payload?.field;
      state.params[field] = payload?.value;
      return state;
    },
    handleSendFilter: (state, { payload }) => {
      state.filter = payload;
    },
    handleShowExpandable: (state, { payload }) => {
      state.show = payload;
    },
  },
});

export const { handleParams, handleSendFilter, handleShowExpandable } =
  taxLogsSlice.actions;

export default taxLogsSlice.reducer;
