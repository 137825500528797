export const Format = (value, valueDefault = "0,00") =>
  value.toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
  }) || valueDefault;

export const toFix = value => (value ? value.toFixed(2) : 0);

export const percentualDiff = (a, b) => {
  if (b > a) return -1 * (100 * Math.abs((a - b) / ((a + b) / 2)));
  return 100 * Math.abs((a - b) / ((a + b) / 2));
};

export const formatDecimals = value =>
  value >= 1000 ? Format(+value).replace("R$", "").trim().split(",")[0] : value;
