import { transformListSelect } from "utils";

export const CLASSIFICAR_ARR_STATUS_PROD = {
  Novos: "Novos",
  Incompletos: "Incompletos",
  Revisar: "Revisar",
  Revisar2x: "Revisar2x",
  diagnostico: "Diagnóstico",
  revisarDiag: "Diagnóstico  Revisar",
  corrigirDiag: "Diagnóstico Corrigir Todos",
  corrigirDiag3: "Diagnóstico Corrigir Errados",
  corrigirDiag2: "Diagnóstico Corrigir Verificar",
  classificarDiag: "Diagnóstico Classificar",
};

export const CLASSIFICAR_ARR_STATUS = {
  classificacao: "Classificado",
  reclassificacao: "Reclassificado",
  classificacao_e_reclassificacao: "Classificados e Reclassificado",
  especificacao: "Especificado",
  revisao: "Revisado",
  insercao: "Inserido",
  alteracao: "Alterado",
  insercao_pc: "Inserido - Prod. Cliente",
  alteracao_pc: "Alterado - Prod. Cliente",
};
export const CLASSIFICAR_ARR_STATUS_PROD_LIST = transformListSelect(
  CLASSIFICAR_ARR_STATUS_PROD,
);

export const CLASSIFICAR_ARR_STATUS_LIST = transformListSelect(
  CLASSIFICAR_ARR_STATUS,
);
